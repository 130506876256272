import i18n from "../plugins/i18n";

let staticData = {
  statisticsPeriods: [
    { name: i18n.t("gbl-periods-lastyear"), id: 0 },
    { name: i18n.t("gbl-periods-since1year"), id: 1 },
    { name: i18n.t("gbl-periods-thisyear"), id: 2 },
    { name: i18n.t("gbl-periods-last6months"), id: 3 },
    { name: i18n.t("gbl-periods-last90days"), id: 4 },
    { name: i18n.t("gbl-periods-last60days"), id: 5 },
    { name: i18n.t("gbl-periods-last30days"), id: 6 },
    { name: i18n.t("gbl-periods-lastmonth"), id: 7 },
    { name: i18n.t("gbl-periods-thismonth"), id: 8 },
    { name: i18n.t("gbl-periods-today"), id: 9 },
    { name: i18n.t("gbl-periods-yesterday"), id: 10 },
    { name: i18n.t("gbl-periods-24h"), id: 11 },
    { name: i18n.t("gbl-periods-48h"), id: 12 },
    { name: i18n.t("gbl-periods-date"), id: 13 },
    { name: i18n.t("gbl-periods-all"), id: 14 },
  ],

  practicesStates: [
    { name: i18n.t("practice-state-pending"), id: 0 },
    { name: i18n.t("practice-state-processing"), id: 1 },
    { name: i18n.t("practice-state-void"), id: 2 },
    { name: i18n.t("practice-state-completed"), id: 3 },
  ],

  eventsStates: [
    { name: i18n.t("event-state-pending"), id: 0 },
    { name: i18n.t("event-state-processing"), id: 1 },
    { name: i18n.t("event-state-void"), id: 2 },
    { name: i18n.t("event-state-to-be-integrated"), id: 3 },
    { name: i18n.t("event-state-completed"), id: 4 },
  ],

  quotesStates: [
    { name: i18n.t("quote-state-pending"), id: 0 },
    { name: i18n.t("quote-state-processing"), id: 1 },
    { name: i18n.t("quote-state-void"), id: 2 },
    { name: i18n.t("quote-state-completed"), id: 3 },
  ],

  invoicesPaymentsStates: [
    { name: i18n.t("invoice-payment-state-pending"), id: 1 },
    { name: i18n.t("invoice-payment-state-partial"), id: 2 },
    { name: i18n.t("invoice-payment-state-payed"), id: 3 },
    { name: i18n.t("invoice-payment-state-void"), id: 4 },
  ],

  days: [
    { name: i18n.t("day-monday"), id: 1 },
    { name: i18n.t("day-tuesdays"), id: 2 },
    { name: i18n.t("day-wednesday"), id: 3 },
    { name: i18n.t("day-thursday"), id: 4 },
    { name: i18n.t("day-friday"), id: 5 },
    { name: i18n.t("day-saturday"), id: 6 },
    { name: i18n.t("day-sunday"), id: 7 },
  ],

  dataTableFooterOptions: {
    showFirstLastPage: true,
    "items-per-page-options": [5, 10, 20, 50, 100, -1],
  },

  defaultPerPage: 50,
};

export default staticData;
